import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { Container, Row, Column } from '../Grid';
import Video from '../Video';
import Image from '../Image';
import Resource from '../Resource';
import Action from '../Resource/Action';
import Button from '../Button';
import Icon from '../Icon';
import './styles.scss';
import Section from '../Section';
import Subfooter from '../Subfooter';

const EpisodePlayer = ({ episodes, playerLabel, className }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const viewerRef = useRef(null);

  // EPISODE STACK
  const renderEpisodes = () => (
    <>
      {episodes.map((episode, index) => (
        <button
          key={index}
          className="episode-player__episode"
          onClick={() => {
            setActiveIndex(index);
            if (viewerRef.current.getBoundingClientRect()['top'] < 0) {
              viewerRef.current.scrollIntoView({ behavior: 'smooth' });
            }
          }}
        >
          <div
            className={classNames(
              index === activeIndex && 'episode-player__episode--active'
            )}
          >
            <Image filename={episode.posterSrc} className="episode__image" />
          </div>
          <div className="episode-player__description">
            <h5
              className={classNames(
                'episode-player__episode-title',
                index === activeIndex && 'episode-player__episode-title--active'
              )}
            >
              {episode.title}
            </h5>
            <p className="episode-player__episode-duration">
              {episode.duration}
            </p>
            <p className="episode-player__episode-desc">{episode.thmbDes}</p>
          </div>
        </button>
      ))}
    </>
  );

  const renderCurrentVideo = () => {
    const episode = episodes[activeIndex];
    return (
      <Video
        guidSrc={episode.guidSrc}
        poster={episode.posterSrc}
        className="video-player video-player-padding"
        title={episode.title}
      />
    );
  };

  //CURRENT EPISODE-Large vid
  const renderCurrentData = () => {
    const episode = episodes[activeIndex];
    return (
      <>
        <Container className="pl-0">
          <Row>
            <Column size={7} className="vts__video-text">
              <div className="episode-player__intro pb-2">
                <h2 className="vts__video-desc-title">
                  {episode.descriptionTitle}
                </h2>
                <p>{episode.description}</p>
                {episode.mainActivities && (
                  <div className="episode-player__actions">
                    {/* {episode.mainActivities.map((action, i) => (
                      <Button
                        to={action.path}
                        isExternal
                        target="_blank"
                        style={{ fontSize: '1.1rem' }}
                      >
                        <Icon name="download" marginRight />
                        {action.label}
                      </Button>
                    ))} */}
                    <Action
                      label="Activity"
                      type="dropdown"
                      actions={episode.mainActivities}
                    />
                  </div>
                )}
                <h4 className="pt-2" style={{ fontWeight: '700' }}>
                  About the Video Topic Series
                </h4>
                <p>{episode.aboutDesc}</p>
                <h4 className="pt-2" style={{ fontWeight: '700' }}>
                  Classroom conversation starters
                </h4>
                <p style={{ fontWeight: '700' }} className="mb-0">
                  Grades:
                </p>
                <p>{episode.conversationStarterGrade}</p>
                <p>{episode.conversationStarterDesc}</p>
                <ul>
                  <li>{episode.starterOne}</li>
                  <li>{episode.starterTwo}</li>
                  <li>{episode.starterThree}</li>
                  <li>{episode.starterFour}</li>
                </ul>
              </div>
            </Column>
            <Column large={4}>
              <Resource
                contentClassOverride="vts-resource-content"
                className="vts-resource-container"
                subject="VIDEO TOPIC SERIES"
                subjectClass="vts__resource-subject"
                img="VTS-image-EdGuide@2x.jpg"
                title="Educator Guide"
                titleClass="vts__resource-title"
                description="Dive deeper into the concepts discussed in the Video Topic Series using the companion Educator Guide, including key learning objectives, student questions, and ways to connect all the videos in the series. "
                actions={[
                  {
                    label: 'Educator Guide',
                    type: 'dropdown',
                    actions: [
                      {
                        label: 'English',
                        path:
                          '/pdfs/vts/ed-guide/Takeda-BetterHealth-VTSEdGuide-BetterHealthInAction-V0.2.pdf',
                        type: 'download',
                      },
                      {
                        label: 'German',
                        path:
                          '/pdfs/vts/ed-guide/Takeda-BetterHealth-VTSEdGuide-BetterHealthInAction-V0_DE.pdf',
                        type: 'download',
                      },
                      {
                        label: 'Spanish',
                        path:
                          '/pdfs/vts/ed-guide/Takeda-BetterHealth-VTSEdGuide-BetterHealthInAction-V0_ES.pdf',
                        type: 'download',
                      },
                      {
                        label: 'Portuguese',
                        path:
                          '/pdfs/vts/ed-guide/Takeda-BetterHealth-VTSEdGuide-BetterHealthInAction-V0_PT.pdf',
                        type: 'download',
                      },
                      {
                        label: 'Italian',
                        path:
                          '/pdfs/vts/ed-guide/Takeda-BetterHealth-VTSEdGuide-BetterHealthInAction-V0_IT.pdf',
                        type: 'download',
                      },
                      {
                        label: 'French',
                        path:
                          '/pdfs/vts/ed-guide/Takeda-BetterHealth-VTSEdGuide-BetterHealthInAction-V0_FR.pdf',
                        type: 'download',
                      },
                      {
                        label: 'Chinese',
                        path:
                          '/pdfs/vts/ed-guide/Takeda-BetterHealth-VTSEdGuide-BetterHealthInAction-V0_ZH.pdf',
                        type: 'download',
                      },
                      {
                        label: 'Japanese',
                        path:
                          '/pdfs/vts/ed-guide/Takeda-BetterHealth-VTSEdGuide-BetterHealthInAction-V0_JP.pdf',
                        type: 'download',
                      },
                      {
                        label: 'Hindi',
                        path:
                          '/pdfs/vts/ed-guide/Takeda-BetterHealth-VTSEdGuide-BetterHealthInAction-V0_HI.pdf',
                        type: 'download',
                      },
                      {
                        label: 'Russian',
                        path:
                          '/pdfs/vts/ed-guide/Takeda-BetterHealth-VTSEdGuide-BetterHealthInAction-V0_RU.pdf',
                        type: 'download',
                      },
                    ],
                  },
                ]}
              />
            </Column>
          </Row>
        </Container>
      </>
    );
  };

  const renderCurrentSubfooter = () => {
    const episode = episodes[activeIndex];
    return (
      <Subfooter
        className="subfooter__quote-home"
        footerImage={episode.subfooterImage}
        footerText={episode.subfooterQuote}
        footerSourceOne={episode.subfooterName}
        footerSourceTwo={episode.subfooterTitle}
        footerSourceThree={episode.subfooterCompany}
      />
    );
  };

  return (
    <>
      <Section className="pt-0" style={{ position: 'relative', zIndex: '200' }}>
        <Container>
          <Container className={`episode-player ${className}`}>
            <div className="episode-player__content-wrapper">
              <div ref={viewerRef} className="episode-player__video-wrapper">
                <div className="episode-player__video">
                  {renderCurrentVideo()}
                </div>
                <div className="episode-player__video-desktop-spacer"></div>
              </div>
              <div className="episode-player__current-data">
                {renderCurrentData()}
              </div>
              <div className="episode-player__episode-wrapper">
                <div className="episode-player__episode-desktop-spacer"></div>
                <div className="episode-player__episodes">
                  {renderEpisodes()}
                </div>
              </div>
            </div>
          </Container>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row className="home__lang-row">
            <Column size={10}>
              <Image filename="global-languages-promo-box@2x.png" />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className={classNames('subfooter', 'pt-0', 'pb-0')}>
        {renderCurrentSubfooter()}
      </Section>
    </>
  );
};

EpisodePlayer.propTypes = {
  episodes: PropTypes.array.isRequired,
  playerLabel: PropTypes.string,
  className: PropTypes.string,
};

EpisodePlayer.defaultProps = {
  playerLabel: '',
  className: '',
};

export default EpisodePlayer;
